
/***Loader***/
/* Start by setting display:none to make this hidden.
   Then we position it in relation to the viewport window
   with position:fixed. Width, height, top and left speak
   speak for themselves. Background we set to 80% white with
   our animation centered, and no-repeating */
.divLoader {
    position: relative;
}

.divModalLoader {
    display: none;
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .2) url('app-loader.gif') 50% 50% no-repeat;
}

/* When the body has the loading class, we turn
   the scrollbar off with overflow:hidden */
.divLoader.loading {
    overflow: hidden;
}

/* Anytime the body has the loading class, our
   modal element will be visible */
.divLoader.loading .divModalLoader {
    display: block;
}
