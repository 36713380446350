/*Style sur la modale de confirmation*/
.modal.dialog.confirm-dialog .modal-dialog,
.modal.dialog.alert-dialog .modal-dialog,
.modal.dialog.alert-session-expired .modal-dialog {
  max-width: 500px;
}

.app-modal.modal-body {
  padding: 0;
}

.modal.dialog .modal-footer {
  text-align: center;
  margin: 0 auto;
  display: inherit;
}

.modal.dialog .box-content {
  max-width: 300px;
}

.modal.dialog .box-content p {
  font-size: 14px;
}

.modal.dialog .box-content h4 {
  color: #636363;
  font-size: 24px;
  margin: 14px 0;
}

.modal.dialog.confirm-dialog .modal-dialog .modal-content .modal-header,
.modal.dialog.alert-dialog .modal-dialog .modal-content .modal-header,
.modal.dialog.alert-session-expired .modal-dialog .modal-content .modal-header,
.modal.dialog.confirm-dialog .modal-dialog .modal-content .modal-header {
  display: none !important;
}

.modal.dialog .modal-dialog .modal-content .confirm-dialog.btn-primary {
  background-color: #f7286f !important;
  border: 1px solid #f7286f;
  border-color: #f7286f !important;
}

.modal.dialog .modal-dialog .modal-content .confirm-dialog.btn-primary:hover {
  background-color: #fff !important;
  color: #f7286f !important;
  border: 1px solid #f7286f;
  border-color: #f7286f !important;
}

.modal.dialog .icon-box i {
  font-size: 50px;
  margin: 0 auto;
  z-index: 9;
  color: #f7286f;
}

.modal.dialog .icon-box i {
  font-size: 50px;
  margin: 0 auto;
  z-index: 9;
  color: #f7286f;
}

.modal.dialog.alert-session-expired .icon-box i {
  color: #62495B;
}

.modal.dialog.alert-session-expired .modal-content .alert-session-expired.btn-primary {
  background-color: #62495B !important;
  border: 1px solid #62495B;
}

.modal.dialog.alert-session-expired .modal-content .alert-session-expired.btn-primary:hover {
  background-color: #fff !important;
  color: #62495B !important;
  border: 1px solid #62495B;
}

.modal-footer-text {
  position: absolute;
  left: 5px;
}

.modal-preference.modal.show .modal-dialog {
  max-width: 650px;
}

.modal-preference.modal.show .form-input-wrap {
  border: 0;
}

.modal-preference.modal.show .form-bloc-head {
  text-align: left;
}

.registration-amount-card {
  font-family: 'Roboto Condensed Bold', Arial, sans-serif;
  color: #051d4d;
  background-color: #fbf1ed;
  padding: 15px;
  text-transform: uppercase;
}

#sideFormTab .registration-amount-card {
  position: absolute;
  bottom: 0;
}
